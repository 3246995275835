<div class="container mt-5 vh-100">
  <div class="row">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 text-primary">Wallet Address</h1>
    </div>
  </div>
  <div class="card shadow rounded bg-body-tertiary">
    <div class="card-body">
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="submit()">
        <div class="form-row pb-3">
          <div class="col">
            <label for="accountNumber">Wallet Address</label>
            <input
              formControlName="accountNumber"
              type="text"
              class="form-control"
              id="accountNumber"
              required
              [ngClass]="{
                'is-valid': f['accountNumber'].valid && submitted,
                'is-invalid': !f['accountNumber'].valid && submitted
              }"
            />
            <div
              class="invalid-feedback"
              *ngIf="f['accountNumber'].errors && submitted"
            >
              <span
                *ngIf="
                  !f['accountNumber'].valid &&
                  f['accountNumber'].errors['required']
                "
                >Wallet is required.</span
              >
            </div>
          </div>
        </div>
        <div class="form-row pb-3">
          <div class="col">
            <label for="bankId">Payment Method</label>
            <select
              class="browser-default custom-select form-control"
              id="bankId"
              formControlName="bankId"
              autocomplete="off"
              [ngClass]="{
                invalid: f['bankId'].touched && f['bankId'].invalid,
                valid: f['bankId'].valid
              }"
            >
              <option value="" disabled selected>Select</option>
              <option *ngFor="let bank of banks" [value]="bank.Id">
                {{ bank.Name }}
              </option>
            </select>
            <div
              class="invalid-feedback"
              *ngIf="f['bankId'].errors && submitted"
            >
              <div *ngIf="f['bankId'].errors['required']">
                >Payment method is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row pb-3" *ngIf="false">
          <div class="col">
            <label for="instructions">Instructions</label>
            <textarea
              formControlName="instructions"
              type="password"
              class="form-control"
              [ngClass]="{
                'is-valid': f['instructions'].valid && submitted,
                'is-invalid': !f['instructions'].valid && submitted
              }"
              id="instructions"
            ></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <button
              icon="fa fa-check"
              class="btn btn-primary"
              [disabled]="loading"
              type="submit"
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
