<div class="container-fluid" *ngIf="isLoggedOn()"  style="background-color: #f9f9f9 !important;">
  <div class="row">
    <div
      class="sidebar border border-right col-md-3 col-lg-2 p-0 bg-body-tertiary"
    >
      <div
        class="offcanvas-md offcanvas-end bg-body-tertiary"
        tabindex="-1"
        id="sidebarMenu"
        aria-labelledby="sidebarMenuLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebarMenuLabel">{{ title }}</h5>
          <button
            #closeButton
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-label="Close"
          ></button>
        </div>
        <div
          class="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto"
        >
          <ul class="nav flex-column" *ngIf="isAdmin()">
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2 active"
                [routerLink]="['/dashboard']"
                routerLinkActive="router-link-active"
                href="#"
              >
                <svg class="bi"><use xlink:href="#house-fill"></use></svg>
                Dashboard
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                [routerLink]="['/orders']"
                href="#"
              >
                <svg class="bi"><use xlink:href="#file-earmark"></use></svg>
                Orders
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                href="#"
                [routerLink]="['/withdrawals']"
                routerLinkActive="router-link-active"
              >
                <svg class="bi"><use xlink:href="#cart"></use></svg>
                Withdrawals
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                role="button"
                [routerLink]="['/wallet-address']"
                routerLinkActive="router-link-active"
              >
                <svg class="bi">
                  <use xlink:href="#gear-wide-connected"></use>
                </svg>
                Wallet Address
              </a>
            </li>
          </ul>
          <ul class="nav flex-column" *ngIf="!isAdmin()">
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2 active"
                [routerLink]="['/dashboard']"
                routerLinkActive="router-link-active"
                aria-current="page"
                (click)="closeButton.click()"
                href="#"
              >
                <svg class="bi"><use xlink:href="#house-fill"></use></svg>
                Dashboard
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                [routerLink]="['/buy']"
                href="#"
                (click)="closeButton.click()"
              >
                <svg class="bi"><use xlink:href="#file-earmark"></use></svg>
                Buy
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                href="#"
                [routerLink]="['/packages']"
                (click)="closeButton.click()"
                routerLinkActive="router-link-active"
              >
                <svg class="bi"><use xlink:href="#cart"></use></svg>
                Protocols
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                href="#"
                [routerLink]="['/referrals']"
                routerLinkActive="router-link-active"
                (click)="closeButton.click()"
              >
                <svg class="bi"><use xlink:href="#people"></use></svg>
                My Team
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                role="button"
                [routerLink]="['/wallet-address']"
                routerLinkActive="router-link-active"
                (click)="closeButton.click()"
              >
                <svg class="bi">
                  <use xlink:href="#gear-wide-connected"></use>
                </svg>
                Wallet Address
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                role="button"
                [routerLink]="['/change-password']"
                routerLinkActive="router-link-active"
                (click)="closeButton.click()"
              >
                <svg class="bi">
                  <use xlink:href="#gear-wide-connected"></use>
                </svg>
               Change Password
              </a>
            </li>
            <li class="nav-item" [hidden]="true">
              <a class="nav-link d-flex align-items-center gap-2" href="#">
                <svg class="bi"><use xlink:href="#graph-up"></use></svg>
                Reports
              </a>
            </li>
            <hr class="my-3" />

            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                role="button"
                (click)="downloadPresentation()"
              >
                <svg class="bi"><use xlink:href="#graph-up"></use></svg>
                Download Presentation
              </a>
            </li>
          </ul>
          <hr class="my-3" />

          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <a
                class="nav-link d-flex align-items-center gap-2"
                role="button"
                (click)="logOut()"
              >
                <svg class="bi"><use xlink:href="#door-closed"></use></svg>
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div class="overflow-y-auto">
        <router-outlet class="bg-body-tertiary"></router-outlet>
      </div>
    </main>
  </div>
</div>
