<div class="card shadow rounded  bg-body-tertiary">
  <div class="card-header">
    <div class="card-title">
      <div class="row">
        <div class="col-6">
          <h5>{{ withdrawal.ClientFullName }}</h5>
        </div>
        <div class="col-6">
          <span class="text-success float-end"
            ><b>{{ withdrawal.Amount | currency : "$" }}</b></span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p class="row">
      <span class="col-6">Payment Method:</span>
      <span class="col-6">{{ withdrawal.PaymentMethod }}</span>
    </p>
    <p class="row">
      <span class="col-6">Amount:</span>
      <span class="col-6 d-flex d-flex-row">
        <span class="col"> {{ withdrawal.Amount | currency : "$" }}</span>
        <button class="btn btn-sm btn-outline-secondary" (click)="copyAmount()">
          Copy
        </button>
      </span>
    </p>
    <div class="row">
      <div class="col-6">Wallet Address:</div>
      <div class="col-6 d-flex flex-row">
        <div class="flex-grow-1">
          <p class="text-wrap">{{ withdrawal.AccountOrWalletAddress }}</p>
        </div>
        <div class="">
        <button
          class="btn btn-sm btn-outline-secondary"
          (click)="copyAccountAddress()"
        >
          Copy
        </button>
      </div>
      </div>
    </div>
    <p class="row">
      <span class="col-6">Date Requested:</span>
      <span class="col-6">{{ withdrawal.DateCreated | date }}</span>
    </p>
    <hr />
    <p class="row">
      <span class="col-6">Contact Number:</span>
      <span class="col-6">{{ withdrawal.ClientContactNumber }}</span>
    </p>
    <p class="row">
      <span class="col-6">Email Address:</span>
      <span class="col-6">{{ withdrawal.ClientEmailAddress }}</span>
    </p>
    <hr />
    <p class="row">
      <span class="col-12"><h5>Instructions:</h5></span>
      <span class="col-12">{{ withdrawal.Instructions }}</span>
    </p>
  </div>
  <div class="card-footer">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <button
          class="btn btn sm btn-primary"
          [disabled]="loading"
          (click)="confirm()"
        >
          Confirm
        </button>
      </div>
      <div class="p-2">
        <button
          class="btn btn sm btn-outline-danger"
          [disabled]="loading"
          (click)="decline()"
        >
          Decline
        </button>
      </div>
    </div>
  </div>
</div>
