import { Component, OnInit } from '@angular/core';
import { OrderService, ProductService } from '../_services';
import { Product } from '../_models';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.css']
})
export class InvestComponent implements OnInit {

  pendingOrder!: any;
  products: Product[] = [];
  amounts: number[] = [];
  form!: FormGroup;
  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private toastr: ToastrService
  ) {
    this.productService.getProducts().subscribe((products: Product[]) => {
      this.products = products;
    });
    this.orderService.getPendingOrder().subscribe((order: any) => {
      this.pendingOrder = order;
    });
  }
  ngOnInit(): void {
    this.buildForm();
  }
  loadProducts(): void {

  }
  buildForm(): void {
    this.form = this.formBuilder.group({
      package: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
    });
  }
  placeOrder() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const model = {
      ProductId: this.package.value,
      Amount: this.amount.value
    }
    this.orderService.placeOrder(model).subscribe(success => {
      this.toastr.success('Your order was placed successfully', 'Order Request Success');
    }, reject => {
      this.toastr.error(reject.error, 'Order Request Failed');
    })
  }
  updateMinimumAndMaximumInvestmentAmount(e: any): void {
    const selectedProduct = this.products.find(x => x.Id === this.package.value);
    if (selectedProduct) {
      this.getInvestmentAmounts(selectedProduct);
      this.amount.clearValidators();
      this.amount.setValidators([Validators.required, Validators.min(selectedProduct.MinimumAmount), Validators.max(selectedProduct.MaximumAmount)])
      this.form.updateValueAndValidity();
      this.amount.reset();
    }
  }
  getInvestmentAmounts(selectedProduct: Product): number[] {

    if (selectedProduct) {
      this.amounts = [];
      for (let i = selectedProduct.MinimumAmount; i <= selectedProduct.MaximumAmount; i += selectedProduct.MinimumAmount) {
        this.amounts.push(i);
      }
    }
    return [];
  }
  get f() {
    return this.form.controls;
  }
  get package(): AbstractControl {
    return this.f['package'];
  }

  get amount(): AbstractControl {
    return this.f['amount'];
  }
  attachProofOfPayment(): void {
    
  }
}
