import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentService } from '../_services/payment.service';
import { PaymentView } from '../_models/payment-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewPopComponent } from '../view-pop/view-pop.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent {
  @Input() payment!: PaymentView;
  @Output() refreshPayments: EventEmitter<any> = new EventEmitter();
  constructor(private paymentService: PaymentService, private modalService: NgbModal, private toastr: ToastrService) {


  }
  viewPop(): void {
    if (this.payment && this.payment.ProofOfPaymentId) {
      const modalRef = this.modalService.open(ViewPopComponent);
      modalRef.componentInstance.proofOfPaymentId = this.payment.ProofOfPaymentId;
    }
  }
  copyTransactionID(): void {
    navigator.clipboard.writeText(this.payment.ProofOfTransaction).then((x) => {
      this.toastr.success('Transaction ID copied successfully');
    });

  }
  approvePayment() {
    this.paymentService.approvePayment(this.payment.Id).subscribe(() => {
      this.toastr.success("Approved successfully");
      this.refreshPayments.emit();
    }, (err: any) => {
      this.toastr.error(err.error?.Message);
    });
  }
  declinePayment() {
    this.paymentService.declinePayment(this.payment.Id).subscribe(() => {
      this.toastr.success("Declined successfully");
      this.refreshPayments.emit();
    }, (err: any) => {
      this.toastr.error(err.error?.Message);

    });
  }

}
