import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../_services/auth.service';
import { StorageService } from '../_services/storage.service';
import { CustomValidators } from '../_shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({ username: new FormControl(), password: new FormControl() });
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  submitted = false;
  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {

    this.buildForm();
  }
  buildForm() {
    this.form = this.formBuilder.group({
      username: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(50),
          CustomValidators.emailValidator,
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(128),
        ],
      ],
    });

  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) { return; }
    const model = { username: this.f['username'].value, password: this.f['password'].value }
    this.authService.login(model.username, model.password).subscribe({
      next: data => {
        this.storageService.saveUser(data);
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.toastr.success('Logged in successfully', 'Login success');
        this.router.navigate(['/dashboard']);

      },
      error: err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
        this.toastr.error(err.error.message, 'Login failed');
      }
    });
  }
  reloadPage(): void {
    window.location.reload();
  }
} 
