import { Component } from '@angular/core';

@Component({
  selector: 'app-purchase-protocol',
  templateUrl: './purchase-protocol.component.html',
  styleUrls: ['./purchase-protocol.component.css']
})
export class PurchaseProtocolComponent {

}
