<div class="container pt-5 vh-100">
  <div class="row">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Withdrawals</h1>
    </div>
    <div class="row" *ngIf="withdrawals.length">
      <div class="col-12 col-lg-6 mb-4" *ngFor="let item of withdrawals">
        <app-withdrawal
          [withdrawal]="item"
          (refresh)="getPendingWithdrawals()"
        ></app-withdrawal>
      </div>
    </div>

    <div class="row" *ngIf="this.withdrawals.length == 0">
      <div class="card shadow rounded">
        <div class="card-body">
          <h5>No Withdrawals Available</h5>
        </div>
      </div>
    </div>
  </div>
</div>
