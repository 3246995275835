import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { isEmpty } from 'ramda';
import { IProfile } from '../_models';
import { ApplicationStoreService, UserService } from '../_services';
import { CustomValidators } from '../_shared';
import { ProfileSelector } from '../_store/selectors/get-profile-selector';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  form: FormGroup = new FormGroup({
    name: new FormControl(),
    surname: new FormControl(),
    phoneNumber: new FormControl(),
    email: new FormControl()
  });
  profile: IProfile = {} as IProfile;
  submitted = false;
  constructor(
    private appStore: ApplicationStoreService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private userService: UserService
  ) { }
  ngOnInit(): void {
    this.userService.getUserProfile().subscribe((profile: any) => {
      if (!isEmpty(profile)) {
        this.profile = profile;
        this.buildForm();
      }

    });

  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      name: [this.profile.Name || "", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      surname: [this.profile.Surname || "", [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      email: [{ value: this.profile.EmailAddress || "", disabled: true }, [Validators.required, CustomValidators.emailValidator]],
      phoneNumber: [this.profile.PhoneNumber || "", [Validators.required, Validators.minLength(10), CustomValidators.phoneNumberValidator]]
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  submit(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    const model = {
      Name: this.f['name'].value,
      Surname: this.f['surname'].value,
      EmailAddress: this.f['email'].value,
      IdentityNumber: null,
      PhoneNumber: this.f['phoneNumber'].value,
    };

    this.userService.updateUserProfile(model).subscribe((results) => {
      this.toaster.success('Profile updated successfully', 'Update succcess');
    }, err => {
      this.toaster.error(err.error);
    })

  }

}
