<div class="container pt-5 vh-100">
  <div class="row">
    <div class="col pb-3">
      <button class="btn btn-primary" (click)="shareMyLink()">
        Share my link
      </button>
    </div>
  </div>
  <div class="row" *ngIf="this.referrals.length">
    <div class="col">
      <div class="card shadow rounded">
        <div class="card-header">
          <div class="d-flex">
            <h4 class="col">My Referrals</h4>
            <div> <select class="form-select" (change)="onLevelSelected($event)">
          <option selected>Select level</option>
          <option value="1">Level 1</option>
          <option value="2">Level 2</option>
          <option value="3">Level 3</option>
          <option value="4">Level 4</option>
        </select>
      </div> 
    </div>
  </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col" sortable="name">Name</th>
                  <th scope="col" sortable="area">Email</th>
                  <th scope="col" sortable="Sponsor">Sponsor</th>
                  <th scope="col"  >Sponsor Email</th>
                  <th scope="col"  >Rank</th>
                  <th scope="col"  >Active Protocols</th>
                  <th scope="col"  >Paid Protocols</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of referrals
                      | slice : (page - 1) * pageSize : page * pageSize;
                    let indx = index
                  "
                >
                  <td>{{ indx + 1 }}</td>
                  <td>{{ item.Name + " " + item.Surname }}</td>
                  <td>{{ item.Email }}</td>
                  <td>{{ item.Sponsor }}</td>
                  <td>{{ item.SponsorEmail }}</td>
                  <td>{{ item.Rank }}</td>
                  <td>{{ item.ActiveProtocols }}</td>
                  <td>{{ item.PaidProtocols }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-between p-2">
            <ngb-pagination
              [collectionSize]="totalCount"
              [(page)]="page"
              [pageSize]="pageSize"
            >
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="this.referrals.length == 0">
    <div class="col">
      <div class="card shadow rounded">
        <div class="card-body">
          <p class="card-title">No Referrals</p>
        </div>
      </div>
    </div>
  </div>
</div>
