import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent {
  title: string = environment.appName;
  constructor(private authService: AuthService, private router: Router) {


  }
  isLoggedOn(): boolean {
    return this.authService.isLoggedOn();
  }
  logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl('/home').then();
  }
  isAdmin(): boolean {
    return this.authService.isAdmin();

  }
  downloadPresentation() {
    window.open(environment.presentationUrl);
  }
}
