import { Component, OnInit } from '@angular/core';
import { WithdrawalService } from '../_services/withdrawal.service';
import { WithdrawalViewModel } from '../_models/withdrawal-view-model';

@Component({
  selector: 'app-withdrawals',
  templateUrl: './withdrawals.component.html',
  styleUrls: ['./withdrawals.component.css']
})
export class WithdrawalsComponent implements OnInit {
  withdrawals: WithdrawalViewModel[] = [];
  constructor(private withdrawalService: WithdrawalService) {


  }
  ngOnInit(): void {
   this. getPendingWithdrawals();
  }
  getPendingWithdrawals(): void {
    this.withdrawalService.getPendingWithdrawals().subscribe((res: any) => {
      if (res.data) {
        this.withdrawals = res.data;
      } else {
        this.withdrawals = [];
      }
    })
  }
}
