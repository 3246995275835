import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';
import jwtDecode from "jwt-decode";
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from './storage.service';
const BASE_URL = environment.apiUrl + '/api/account/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private storageService: StorageService) { }
  login(username: string, password: string): Observable<any> {
    return this.http.post(BASE_URL + 'login', {
      username,
      password
    }, httpOptions)
  }

  register(model: any): Observable<any> {
    return this.http.post(BASE_URL + 'signup', model, httpOptions)
  }

  DecodeToken(token: string): string {

    return jwtDecode(token);
  }

  isLoggedOn(): boolean {
    const token = this.storageService.getToken();
    if (token) {
      return !new JwtHelperService().isTokenExpired(token);
    }
    return false;
  }
  logout(): void {
    localStorage.clear();
    sessionStorage.clear(); 
  }
  isAdmin(): boolean {
    if (this.isLoggedOn()) {
      return this.storageService.getUser().Role === "Admin";
    }
    return false;
  }
  getUserProfile() {
    if (this.isLoggedOn()) {
      return this.storageService.getUser();
    }
    return {};
  }
}
