<div class="modal-header text-center d-block">
  <h2 class="text-center"><span>INCOME DISCLAIMER</span></h2>
</div>
<div class="modal-body">
  <div class="text-center">
    <p>
      The information provided in this documentation should always be considered
      as general information about Cryptocurrencies.
    </p>

    <p>
      We strive to accurately represent the information provided by
      UNISMARTSWAP, its benefits, the educational element it provides, as well
      as the referral rewards plan and its benefits. There are no guarantees
      regarding benefits or rewards from UNISMARTSWAP. Under no circumstances
      shall this presentation be considered as any type of solicitation,
      investment or financial advice.
    </p>
    <p>
      Information is simply provided in general terms without taking into
      consideration your financial or personal information. Any mention of
      potential benefits made in this presentation solely describes the
      UNISMARTSWAP reward plan itself. Your participation in this project
      involves risk. You can seek an advice of a professional financial advisor
      or expert so that you can make an informed decision.
    </p>
    <p>
      Your achievements, or rank levels are dependent upon individual members’
      skills, ambition, commitment & expertise, each individual results may
      vary. It is crucial that the use of this information, products, and
      services is based on your own due diligence. By engaging with
      UNISMARTSWAP, you agree that UNISMARTSWAP is not liable for any success or
      failure of your pursuits in your business endeavors.
    </p>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col">
      <button class="btn btn-danger btn-sm" type="button" (click)="decline()">
        DECLINE
      </button>
    </div>
    <div class="col">
      <button class="btn btn-primary btn-sm" type="button" (click)="accept()">
        ACCEPT
      </button>
    </div>
  </div>
</div>
