import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl + '/api';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + 'all', { responseType: 'text' });
  }
  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + 'user', { responseType: 'text' });
  }
  getModeratorBoard(): Observable<any> {
    return this.http.get(API_URL + 'mod', { responseType: 'text' });
  }
  getAdminBoard(): Observable<any> {
    return this.http.get(API_URL + 'admin', { responseType: 'text' });
  }
  getUserProfile(): Observable<any> {
    return this.http.get(API_URL + "/profile/getUserProfile", httpOptions);
  }
  updateUserProfile(model: any): Observable<any> {
    return this.http.post(API_URL + "/profile/updateUserProfile", model, httpOptions);
  }
  changePassword(model: any): Observable<any> {
    return this.http.post(API_URL + "/profile/changePassword", model, httpOptions);
  }
  getUserBankingDetails(): Observable<any> {
    return this.http.get(API_URL + "/bank/GetUserBankingDetails", httpOptions);

  }
  updateUserBankingDetails(model: any) {
    return this.http.post(API_URL + "/profile/updateUserBankingDetails", model, httpOptions);

  }

}
