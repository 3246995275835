import { Component, Input, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../_services/auth.service';
import { CustomValidators } from '../_shared';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  private route = inject(ActivatedRoute);
  form: FormGroup = new FormGroup({
    firstName: new FormControl(),
    lastName: new FormControl(),
    phoneNumber: new FormControl(),
    email: new FormControl(),
    password: new FormControl(),
    confirmPassword: new FormControl(),
    sponsor: new FormControl()
  });
  isRegistered = false;
  errorMessage = '';
  submitted = false;
  constructor(
    private authService: AuthService,
    private toaster:
      ToastrService,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {

    this.buildForm();
  }
  buildForm() {

    const sponsor = this.route.snapshot.paramMap.get('sponsor');

    console.log(sponsor)
    this.form = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255)
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
        ],
      ],
      /*phoneNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(25),
          CustomValidators.phoneNumberValidator
        ],
      ],*/
      email: [
        "",
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(255),
          CustomValidators.emailValidator
        ],
      ],
      sponsor: [
        sponsor,
        [
          Validators.minLength(5),
          Validators.maxLength(255),
          CustomValidators.emailValidator
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(1000)
        ],
      ],
      confirmPassword: [
        "",
        [
          Validators.required,
          CustomValidators.confirmPassdwordMatch('password')
        ],
      ],
    });

  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) { return; }
    const model = {
      name: this.f['firstName'].value,
      surname: this.f['lastName'].value,
      emailAddress: this.f['email'].value,
      password: this.f['password'].value,
      sponsor: this.f['sponsor'].value,
    }
    this.authService.register(model).subscribe({
      next: data => {
        this.isRegistered = true;
        this.toaster.success('Registered successfully', 'Success');
        setTimeout(() => {
          this.router.navigate(['/login']);
        });
      },
      error: err => {
        this.errorMessage = err.error;
        this.isRegistered = false;
        this.toaster.error(err.error, 'Registration failed');

      }
    });
  }
  reloadPage(): void {
    window.location.reload();
  }
} 
