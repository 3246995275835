import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvestmentViewDto } from '../_models/investment-view-model';
import { ToastrService } from 'ngx-toastr'; 
import { InvestmentService } from '../_services/investment.service';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.css']
})
export class InvestmentComponent {
  @Input() investment!: InvestmentViewDto;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  amount: number = 0;
  constructor(private investmentService: InvestmentService, private toastr: ToastrService) {


  }

  transferToWallet(): void {
    if (this.investment && this.amount > 0) {
      const model = {
        InvestmentId: this.investment.Id,
        Amount: this.amount
      };
      this.investmentService.transferToWallet(model).subscribe(() => {
        this.toastr.success("Transferred successfully.");
        this.refresh.emit();
      }, (err: any) => {
        this.toastr.error(err.error.Message ? err.error.Message : err.error);
      })
    }
  }
}
