import { Component, OnInit } from '@angular/core';
import { InvestmentService } from '../_services/investment.service';
import { InvestmentViewDto } from '../_models/investment-view-model';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.css']
})
export class InvestmentsComponent implements OnInit {

  investments: InvestmentViewDto[] = [];
  constructor(private investmentService: InvestmentService) { }
  ngOnInit(): void {
    this.getInvestments();
  }

  getInvestments(): void{
    this.investmentService.getActiveInvestments().subscribe((response: any) => {
      if (response.data) {
        this.investments = response.data
      }
    });
  }

}
