<!-- ======= Header ======= -->
<header id="header" class="fixed-top d-flex align-items-center">
  <div class="container d-flex align-items-center">
    <div class="logo me-auto">
      <h1 class="d-flex flex-row">
        <div class="mx-3">
          <i
            class="bi bi-list mobile-nav-toggle"
            [ngClass]="{ 'bi-x': mobileMenuCollapse }"
            (click)="mobileMenuCollapse = !mobileMenuCollapse"
          ></i>
        </div>
        <a [routerLink]="['/home']" routerLinkActive="router-link-active"
          >UniSmartSwap</a
        >
      </h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
    </div>

    <nav
      id="navbar"
      class="navbar order-last order-lg-0"
      [ngClass]="{ 'navbar-mobile': mobileMenuCollapse }"
    >
      <ul>
        <li>
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <a class="nav-link scrollto active" href="#hero">Home</a>
            </div>
            <div class="mx-2 d-lg-none">
              <button
                class="btn btn-primary btn-sm"
                (click)="mobileMenuCollapse = !mobileMenuCollapse"
              >
                <b>Close</b>
              </button>
            </div>
          </div>
        </li>
        <li><a class="nav-link scrollto" href="#about">About</a></li>
        <li><a class="nav-link scrollto" href="#services">Services</a></li>
        <li><a class="nav-link scrollto" href="#products">Products</a></li>
        <li>
          <a class="nav-link scrollto" href="#referrals">Referrals</a>
        </li>

        <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
        <li>
          <a
            class="nav-link"
            [routerLink]="['/login']"
            routerLinkActive="router-link-active"
            >Login</a
          >
        </li>
        <li>
          <a class="nav-link" [routerLink]="['/register']">Register</a>
        </li>
      </ul>
    </nav>
    <!-- .navbar -->

    <div class="header-social-links d-flex align-items-center">
      <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
      <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
      <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
      <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
    </div>
  </div>
</header>
<!-- End Header -->

<!-- ======= Hero Section ======= -->
<section
  id="hero"
  class="d-flex flex-column justify-content-center align-items-center"
>
  <div class="container text-center text-md-left" data-aos="fade-up">
    <h1>Welcome to <span>UniSmartSwap</span></h1>
    <h2>
      Start your lending & borrowing journey with us now. Lets help you grow
      your crypto portfolio.
    </h2>
    <a href="#started" class="btn-get-started scrollto">Get Started</a>
  </div>
</section>
<!-- End Hero -->

<main id="main">
  <!-- ======= What We Do Section ======= -->
  <section id="what-we-do" class="what-we-do">
    <div class="container">
      <div class="section-title">
        <h2>What We Do</h2>
        <p>
          Peer-to-Peer (P2P) financial transactions are one of the core premises
          behind UniSmartSwap where two parties agree to exchange cryptocurrency
          for goods or services without a third party involved.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box">
            <div class="icon"><i class="bx bxl-dribbble"></i></div>
            <h4><a href="">Accessibility</a></h4>
            <p>
              Anyone with an internet connection can access a UniSmartSwap
              platform, and transactions occur without geographic restrictions.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-file"></i></div>
            <h4><a href="">Low fees and high-interest rates</a></h4>
            <p>
              Any two parties to negotiate interest rates directly and lend
              cryptocurrency or money via UniSmartSwap networks.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bx bx-tachometer"></i></div>
            <h4><a href="">Autonomy</a></h4>
            <p>
              UniSmartSwap platform don't rely on Centralized Financial
              Institutions. UniSmartSwap protocols mitigates the need for and
              costs of administering financial services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End What We Do Section -->

  <!-- ======= About Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img src="assets/img/about.jpg" class="img-fluid" alt="" />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0">
          <h3>About Us</h3>
          <p>
            UniSmartSwap allow people to lend or borrow funds from others,
            speculate on price movements on assets using Derivatives, trade
            Cryptocurrencies, insure against risks, and earn interest in
            savings-like accounts. UniSmartSwap uses a layered architecture and
            highly composable building blocks. UniSmartSwap challenges this
            centralized financial system by empowering individuals with
            peer-to-peer transactions.
          </p>
          <p>
            Through peer-to-peer financial networks, UniSmartSwap uses security
            protocols, connectivity, software, and hardware advancements. This
            system eliminates intermediaries like banks and other financial
            service companies. These companies charge businesses and customers
            for using their services, which are necessary in the current system
            because it's the only way to make it work. UniSmartSwap uses
            blockchain technology as a way to reduce the need for these
            intermediaries.
          </p>

          <div class="row icon-boxes">
            <div class="col-md-6">
              <i class="bx bx-receipt"></i>
              <h4>Lenders</h4>
              <p>
                For Lenders, UniSmartSwap offers an opporutinity to earn
                interest income on your crypto holdings and fiat.
              </p>
            </div>
            <div class="col-md-6 mt-4 mt-md-0">
              <i class="bx bx-cube-alt"></i>
              <h4>Borrowers</h4>
              <p>
                Other than selling your crypto assets, lending is one way to
                earn returns on your crypto. Instead of waiting for prices to
                appreciate before selling, you can make passive income on
                UniSmartSwap by lending it to borrowers and earning interest in
                return. On the flip side, borrowers maximize the value of their
                digital assets with a UniSmartSwap loan instead.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->

  <!-- ======= Skills Section ======= -->
  <section id="skills" class="skills" *ngIf="false">
    <div class="container">
      <div class="row skills-content">
        <div class="col-lg-6">
          <div class="progress">
            <span class="skill">HTML <i class="val">100%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">CSS <i class="val">90%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">JavaScript <i class="val">75%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="progress">
            <span class="skill">PHP <i class="val">80%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="80"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">WordPress/CMS <i class="val">90%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="90"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <div class="progress">
            <span class="skill">Photoshop <i class="val">55%</i></span>
            <div class="progress-bar-wrap">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="55"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Skills Section -->

  <!-- ======= Counts Section ======= -->
  <section id="products" class="counts">
    <div class="container">
      <div class="section-title">
        <h2>Contract Protocols</h2>
        <p>
          There are 5 contract protocols with minimum of $100 and maximum of
          $10,000 of peer donations.
        </p>
      </div>
      <div class="row mt-5">
        <div class="row">
          <div class="col py-3">
            <div class="count-box">
              <i class="bi bi-emoji-smile"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="232"
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <h5>Action Plan</h5>
              <p>$100 - $499</p>
              <p>ROI - 1% Daily</p>
            </div>
          </div>

          <div class="col py-3">
            <div class="count-box">
              <i class="bi bi-journal-richtext"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="521"
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <h5>Bold Plan</h5>
              <p>$500 - $999</p>
              <p>ROI - 1.2% Daily</p>
            </div>
          </div>

          <div class="col py-3">
            <div class="count-box">
              <i class="bi bi-headset"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="1463"
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <h5>Character Plan</h5>
              <p>$1000 - $1999</p>
              <p>ROI - 1.3% Daily</p>
            </div>
          </div>

          <div class="col py-3">
            <div class="count-box">
              <i class="bi bi-people"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <h5>Dream Plan</h5>
              <p>$2,000 - $,4999</p>
              <p>ROI - 1.5% Daily</p>
            </div>
          </div>

          <div class="col py-3">
            <div class="count-box">
              <i class="bi bi-people"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <h5>Elite Plan</h5>
              <p>$5,000 - $10,000</p>
              <p>ROI - 2% Daily</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Counts Section -->
  <!-- ======= Testimonials Section ======= -->
  <section id="referrals" class="testimonials section-bg">
    <div class="container">
      <div class="section-title">
        <h2>REFERRALS</h2>
        <p class="bg-info p-2 mx-2">
          NOTE: Referral bonuses allow you to accelerate the speed to Double
          your rewards as they get deducted from the 2x Pending Reward.
        </p>
        <p>REFERRAL REWARDS PROGRAM</p>
      </div>

      <div
        class="testimonials-slider swiper"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="testimonial-item">
              <p>
                <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                Additional rewards get up to 23% in referral bonuses.
                <i class="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
              <div class="row">
                <div class="col my-3">
                  <div class="card p-3">
                    <img
                      src="assets/img/testimonials/referral.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <p>Level 1</p>
                    <p><b>10%</b></p>
                  </div>
                </div>
                <div class="col my-3">
                  <div class="card p-3">
                    <img
                      src="assets/img/testimonials/referral.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <p>Level 2</p>
                    <p><b>7%</b></p>
                  </div>
                </div>
                <div class="col my-3">
                  <div class="card p-3">
                    <img
                      src="assets/img/testimonials/referral.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <p>Level 3</p>
                    <p><b>5%</b></p>
                  </div>
                </div>
                <div class="col my-3">
                  <div class="card p-3">
                    <img
                      src="assets/img/testimonials/referral.png"
                      class="testimonial-img"
                      alt=""
                    />
                    <p>Level 4</p>
                    <p><b>1.5%</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <!-- End Testimonials Section -->
  <!-- ======= Services Section ======= -->
  <section id="services" class="services section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Rank Rewards</h2>
        <p>
          There are 11 ranks that can only be reached by the amount you earn
          from referral bonuses only.
        </p>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="icon-box">
            <i class="bi bi-briefcase"></i>
            <h4><a href="#">Rank 1 - Member</a></h4>
            <p>Requirement: $500 referral earnings on level 1</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-lg-0">
          <div class="icon-box">
            <i class="bi bi-card-checklist"></i>
            <h4><a href="#">Rank 2 - Earner</a></h4>
            <p>Requirement: $1000 referral earnings on level 1</p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-bar-chart"></i>
            <h4><a href="#">Rank 3 - Leader</a></h4>
            <p>Requirement: $1500 referral earnings on level 1</p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-binoculars"></i>
            <h4><a href="#">Rank 4 - Bronze</a></h4>
            <p>Requirement: $2500 referral earnings on level 1</p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-brightness-high"></i>
            <h4><a href="#">Rank 5 - Silver</a></h4>
            <p>Requirement: $7,500 referral earnings on level 1</p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-bricks"></i>
            <h4><a href="#">Rank 6 - Gold</a></h4>
            <p>Requirement: $15,000 referral earnings on level 1 & 2</p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-filetype-rb"></i>
            <h4><a href="#">Rank 7 - Ruby</a></h4>
            <p>
              Requirement: $35,000 referral earnings on level 1, level 2 & level
              3
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-calendar4-week"></i>
            <h4><a href="#">Rank 8 - Platinum</a></h4>
            <p>
              Requirement: $80,000 referral earnings on level 1, level 2, level
              3 & level 4
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-diamond"></i>
            <h4><a href="#">Rank 9 - Diamond</a></h4>
            <p>
              Requirement: $150,000 referral earnings on level 1, level 2, level
              3 & level 4
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-x-diamond"></i>
            <h4><a href="#">Rank 10 - Diamond Gold</a></h4>
            <p>
              Requirement: $350,000 referral earnings on level 1, level 2, level
              3 & level 4
            </p>
          </div>
        </div>
        <div class="col-md-6 mt-4">
          <div class="icon-box">
            <i class="bi bi-gem"></i>
            <h4><a href="#">Rank 11 - Diamond Platinum</a></h4>
            <p>
              Requirement: $800,000 referral earnings on level 1, level 2, level
              3 & level 4
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Portfolio Section ======= -->
  <section id="started" class="portfolio">
    <div class="container">
      <div class="section-title">
        <h2>HOW DO I BECOME A LENDER IN UNISMARTSWAP?</h2>
        <p>Follow the simple steps below</p>
      </div>

      <div class="row portfolio-container">
        <div class="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp">
          <div class="portfolio-wrap h-75">
            <div class="card border border-0 p-2 m-0">
              <div class="card-header border border-0">
                <div class="count-box">
                  <h1><i class="bi bi-1-circle-fill icon-blue"></i></h1>
                </div>
              </div>
              <div class="card-body">
                <p>
                  Users who want to become lenders deposit their USDT into
                  UniSmartSwap. You select the type of contract protocol
                  according to the amount of USDT you want to Lend. In return
                  you will get a daily reward according to what your contract
                  protocol pays.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp">
          <div class="portfolio-wrap h-75">
            <div class="card border border-0 p-2 m-0">
              <div class="card-header border border-0">
                <div class="count-box">
                  <h1><i class="bi bi-2-circle-fill icon-blue"></i></h1>
                </div>
              </div>
              <div class="card-body">
                <p>
                  All contracts pay up to 2x your USDT contribution. The
                  duration of time it takes to 2x your rewards is dependent on a
                  contract type/protocol and referral bonuses received if any.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-app wow fadeInUp">
          <div class="portfolio-wrap h-75">
            <div class="card border border-0 p-2 m-0">
              <div class="card-header border border-0">
                <div class="count-box">
                  <h1><i class="bi bi-3-circle-fill icon-blue"></i></h1>
                </div>
              </div>
              <div class="card-body">
                <p>
                  Rewards earned are determined by the ratio that exist between
                  the supplied and borrowed asset in a particular market.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Portfolio Section -->

  <!-- ======= Team Section ======= -->
  <section id="team" class="team">
    <div class="container">
      <div class="section-title">
        <h2>WHY BORROW AND LEND IN UNISMARTSWAP</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <h1><i class="bi bi-building-gear icon-blue"></i></h1>
            <h4>UniSmartSwap Borrowing</h4>
            <p class="text-decoration-none">
              UniSmartSwap borrowing and lending offers innovations in
              efficiency, access and transparency compared to Centralised
              Finance (CeFi). In contrast to traditional banks and CeFi
              institutions, UniSmartSwap allows any users to become a lender
              without having to hand over personal information, identity or
              undergo KYC (know your customer) procedures.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <h1><i class="bi bi-buildings icon-blue"></i></h1>
            <h4>UnitSmartSwap Lending</h4>
            <p>
              Most people are familiar with the concept of borrowing and lending
              whether it be in the form of mortgages, student loans or similar
              etc. It is one of the core aspects of the financial system.
              Lenders provide borrowers with funds with the expectation that the
              borrowed amount is paid back with additional interest for
              providing immediate access to funds.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="member">
            <h1><i class="bi bi-megaphone icon-blue"></i></h1>
            <h4>UniSmartSwap Efficiencies</h4>
            <p>
              UniSmartSwap borrowing and lending offers innovations in
              efficiency, access and transparency compared to CeFi (Centralised
              Finance).
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section -->

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Contact</h2>
        <p>
          How to get connected with the unismartswap team, please see our
          contacts and links below.
        </p>
      </div>

      <div class="row mt-5 justify-content-center">
        <div class="col-lg-10">
          <div class="info-wrap">
            <div class="row">
              <div class="col-lg-6 info">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Online<br />www.unismartswap.com</p>
              </div>

              <div class="col-lg-6 info mt-4 mt-lg-0">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@unismartswap.com<br />contact@unismartswap.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</main>
<!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 footer-contact">
          <h3>UniSmartSwap</h3>
          <p><strong>Email:</strong> info@unismartswap.com<br /></p>
        </div>
        <div class="col">
          <img src="assets/img/TikTokQR.jpeg" width="150px" alt="">
        </div>
        <div class="col">
          <img src="assets/img/TelegramQRCode.jfif" width="150px" alt="">
        </div>
      </div>
    </div>
  </div>

  <div class="container d-md-flex py-4">
    <div class="me-md-auto text-center text-md-start">
      <div class="copyright">
        &copy; Copyright <strong><span>UniSmartSwap</span></strong
        >. All Rights Reserved
      </div>
      <div class="credits"></div>
    </div>
    <div class="social-links text-center text-md-right pt-3 pt-md-0">
      <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
      <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
      <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
      <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
      <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
    </div>
  </div>
</footer>
<!-- End Footer -->

<a href="#" class="back-to-top d-flex align-items-center justify-content-center"
  ><i class="bi bi-arrow-up-short"></i
></a>

<!-- Vendor JS Files -->
<script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
<script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
<script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
<script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
<script src="assets/vendor/waypoints/noframework.waypoints.js"></script>
<script src="assets/vendor/php-email-form/validate.js"></script>

<!-- Template Main JS File -->
<script src="assets/js/main.js"></script>
