import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from '../_services/payment.service';

@Component({
  selector: 'app-view-pop',
  templateUrl: './view-pop.component.html',
  styleUrls: ['./view-pop.component.css']
})
export class ViewPopComponent implements OnInit {

  @Input() proofOfPaymentId!: string;
  imgUrl!: string;
  constructor(private modalRef: NgbActiveModal, private paymentService: PaymentService) {

  }
  ngOnInit(): void { 
    if (this.proofOfPaymentId) {
      this.paymentService.getPop(this.proofOfPaymentId).subscribe((res: any) => { 
        this.imgUrl = res.Pop;
      });
    }
  }

  close(): void {
    this.modalRef.dismiss();
  }
}
