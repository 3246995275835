import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../_services';
import { InvestmentService } from '../_services/investment.service';
import { InvestmentViewDto } from '../_models/investment-view-model';
import { WithdrawalService } from '../_services/withdrawal.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisclaimerComponent } from '../_shared/disclaimer/disclaimer.component';


export interface DashboardSummary {
  TotalDeposited: number;
  TotalProfit: number;
  TotalWithdrawal: number;
  TotalReferrals: number;
  TotalActivePackages: number;
  TotalPaidPackages: number;
  CurrentWithdrawal: number;
  TotalPackageBalance: number;
  TotalBalance: number;
  ReferralBonus: number;
  Sponsor: any;
  RankView: RankViewDto;
}

export interface RankViewDto {

  Rank: string;
  NextRank: string;
  NextRankPoints: number;
  CurrentPoints: number;
  PercentageToNextRank: number;
  IsNextRankAvailable: Boolean;
  FullName: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dashboardSummary!: DashboardSummary;
  investmentList!: InvestmentViewDto[];
  withdrawalAmount: number = 0;
  constructor(
    private withdrawalService: WithdrawalService,
    private dashboardService: DashboardService,
    private investmentService: InvestmentService,
    private toaster: ToastrService,
    private modalService: NgbModal

  ) {


  }
  ngOnInit(): void {
    this.getDashboardSummary();
    this.getInvestments();
    this.displayDisclaimer();
  }
  getInvestments(): void {
    this.investmentService.getActiveInvestments().subscribe((x: any) => {
      this.investmentList = x.data;
    })
  }
  getDashboardSummary(): void {
    this.dashboardService.getDashboardSummary().subscribe((res: any) => {
      if (res.data) {
        this.dashboardSummary = res.data;
      }
    });
  }
  withdrawFromBalance(): void {
    this.withdrawalService.withdraw({ amount: this.withdrawalAmount }).subscribe(() => {
      this.toaster.success("Withdrawal requested.")
      this.getDashboardSummary();
    }, err => {
      this.toaster.error(err.error, "Failed");
    })

  }
  displayDisclaimer(): void {
    const modal = this.modalService.open(DisclaimerComponent, { backdrop: false });
  }

}
