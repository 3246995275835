<main class="pt-5 vh-100 w-100 m-auto" style="max-width: 500px; min-width: 250px;">
  <div class="card shadow rounded ">
    <div class="card-body">
      <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="d-block text-center">
          <div class="" style="min-width: 250px">
            <img src="/assets/img/unismartlogo.PNG" width="100%" alt="" />
          </div>
        </div>
        <h1 class="h3 mb-3 fw-normal">Create Account</h1>
        <hr />
        <div class="form-group">
          <label for="firstName">Name</label>
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="Enter name"
            name="firstName"
            [ngClass]="{
              'is-valid': f['firstName'].valid && submitted,
              'is-invalid': f['firstName'].errors && submitted
            }"
            formControlName="firstName"
          />

          <div
            class="invalid-feedback"
            *ngIf="f['firstName'].errors && submitted"
          >
            <div *ngIf="f['firstName'].errors['required']">
              Your name is required.
            </div>
            <div *ngIf="f['firstName'].errors['minlength']">
              Your name must be at least 3 characters.
            </div>
            <div *ngIf="f['firstName'].errors['maxlength']">
              Your name must not exceed 255 characters.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="firstName">Surname</label>
          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="Enter surname"
            name="lastName"
            [ngClass]="{
              'is-valid': f['lastName'].valid && submitted,
              'is-invalid': f['lastName'].errors && submitted
            }"
            formControlName="lastName"
          />

          <div
            class="invalid-feedback"
            *ngIf="f['lastName'].errors && submitted"
          >
            <div *ngIf="f['lastName'].errors['required']">
              Your surname is required.
            </div>
            <div *ngIf="f['lastName'].errors['minlength']">
              Your surname must be at least 3 characters.
            </div>
            <div *ngIf="f['lastName'].errors['maxlength']">
              Your surname must not exceed 255 characters.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email address</label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter email eg. ame@example.com"
            name="form"
            [ngClass]="{
              'is-valid': f['email'].valid && submitted,
              'is-invalid': f['email'].errors && submitted
            }"
            formControlName="email"
          />

          <div class="invalid-feedback" *ngIf="f['email'].errors && submitted">
            <div *ngIf="f['email'].errors['required']">Email is required.</div>
            <div *ngIf="f['email'].errors['invalidEmail']">
              Invalid email address.
            </div>
          </div>
        </div>
 
        <div class="form-group">
          <label for="sponsor">Sponsor</label>

          <input
            type="text"
            class="form-control"
            id="floatingInput"
            placeholder="Enter sponsor e.g. sponsor@example.com"
            name="sponsor"
            [ngClass]="{
              'is-valid': f['sponsor'].valid && submitted,
              'is-invalid': f['sponsor'].errors && submitted
            }"
            formControlName="sponsor"
          />
          <div
            class="invalid-feedback"
            *ngIf="f['sponsor'].errors && submitted"
          >
            <div *ngIf="f['sponsor'].errors['required']">
              Sponsor is required.
            </div>
            <div *ngIf="f['sponsor'].errors['invalidEmail']">
              Invalid email address.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Password"
            formControlName="password"
            [ngClass]="{
              'is-valid': f['password'].valid && submitted,
              'is-invalid': f['password'].errors && submitted
            }"
          />

          <div
            class="invalid-feedback"
            *ngIf="f['password'].errors && submitted"
          >
            <div *ngIf="f['password'].errors['required']">
              Password is required
            </div>
            <div *ngIf="f['password'].errors['minlength']">
              Password must be at least 6 characters
            </div>
            <div *ngIf="f['password'].errors['maxlength']">
              Password can not exceed 1000 characters
            </div>
            <div *ngIf="f['password'].errors['invalidPassword']">
              <p>Password must consist of:</p>
              <ul>
                <li>Uppercase letters</li>
                <li>Lowercase letters</li>
                <li>Contains a special character</li>
                <li>Contains numbers</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            placeholder="Confirm Password"
            formControlName="confirmPassword"
            [ngClass]="{
              'is-valid': f['confirmPassword'].valid && submitted,
              'is-invalid': f['confirmPassword'].errors && submitted
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="f['confirmPassword'].errors && submitted"
          >
            <div *ngIf="f['confirmPassword'].errors['required']">
              Password is required.
            </div>
            <div *ngIf="f['confirmPassword'].errors['passwordMismatch']">
              Passwords do not match.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col pt-3"> <button class="btn btn-primary" type="submit">Sign up</button></div>
          
        </div>
       
      </form>
      <hr/>
      <div class="d-block">
        <p class="h6">Already have an account?
        <button class="btn btn-secondary" type="button" [routerLink]="['/login']">Log In</button></p>
      </div>
    </div>
  </div>
</main>
