<button
  [class]="btnClass"
  (click)="click($event)"
  [type]="btnType"
  [disabled]="disabled || loading"
>
  <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
  <i [class]="icon" *ngIf="!loading && icon"></i>
  <span [hidden]="loading">
    {{ btnText }}
  </span>
</button>
