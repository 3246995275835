<main
  class="w-100 vh-100 pt-5"
  style="max-width: 500px; margin-left: auto; margin-right: auto; min-width: 250px;"
>
  <div class="d-flex flex-column">
    <div class="card shadow rounded bg-body-tertiary">
      <div class="card-body">
        <form
          class="flex-grow needs-validation"
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
        >
          <div class="d-block text-center">
            <div class="" style="min-width: 250px">
              <img src="/assets/img/unismartlogo.PNG" width="100%" alt="" />
            </div>
          </div>

          <h1 class="h2 bold mb-3 fw-normal text-primary">Login</h1>
          <hr />

          <div class="form-group">
            <label for="form-label">Email address</label>
            <input
              type="email"
              class="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              name="form"
              [ngClass]="{
                'is-valid': f['username'].valid && submitted,
                'is-invalid': f['username'].errors && submitted
              }"
              formControlName="username"
            />

            <div
              class="invalid-feedback"
              *ngIf="f['username'].errors && submitted"
            >
              <div *ngIf="f['username'].errors['required']">
                Username is required.
              </div>
              <div *ngIf="f['username'].errors['invalidEmail']">
                Invalid email address.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              id="floatingPassword"
              placeholder="Password"
              formControlName="password"
              [ngClass]="{
                'is-valid': f['password'].valid && submitted,
                'is-invalid': f['password'].errors && submitted
              }"
            />

            <div
              class="invalid-feedback"
              *ngIf="f['password'].errors && submitted"
            >
              <div *ngIf="f['password'].errors['required']">
                Password is required
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex flex-row pt-3">
              <button class="btn btn-primary" type="submit">Sign in</button>
            </div>
          </div>
        </form>
        <div class="d-block">
          <hr />
          <p class="text-center">Or</p>
          <div>
            <button
              type="button"
              [routerLink]="['/register']"
              class="btn btn-secondary"
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
