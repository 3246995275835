<div class="card shadow rounded bg-body-tertiary">
  <div class="card-body">
    <h5>
      {{ payment.PayerName + " " + payment.PayerSurname }} :
      <b>{{ payment.ProductName }}</b>
    </h5>
    <hr />

    <p class="row">
      <b class="col-6">Package Name:</b>
      <span class="col-6"> {{ payment.ProductName }}</span>
    </p>
    <p class="row">
      <b class="col-6">Amount: </b>
      <span class="col-6">{{ payment.Amount | currency : "$ " }}</span>
    </p>
    <p class="row">
      <b class="col-6">Date:</b>
      <span class="col-6">{{ payment.DateCreated | date }}</span>
    </p>
    <div class="row">
      <b class="col-6">Transaction ID:</b>
      <div class="col-6">
        <div class="d-block flex-row">
          <div class="flex-grow-1">{{ payment.ProofOfTransaction }}</div>
          <div class="">
            <button
              class="btn btn-sm btn-outline-secondary"
              type="button"
              (click)="copyTransactionID()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-clipboard-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <p class="row">
      <b class="col-6">Email:</b>
      <span class="col-6">{{ payment.PayerEmail }}</span>
    </p>

    <div class="row">
      <hr />
      <div class="col mb-1">
        <button class="btn btn-sm btn-primary" (click)="approvePayment()">
          Approve
        </button>
      </div>
      <div class="col mb-1" *ngIf="payment.ProofOfPaymentId">
        <button class="btn btn-sm btn-secondary" (click)="viewPop()">
          View POP
        </button>
      </div>
      <div class="col mb-1">
        <button
          class="btn btn-sm btn-outline-danger"
          (click)="declinePayment()"
        >
          Decline
        </button>
      </div>
    </div>
  </div>
</div>
