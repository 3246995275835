import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { httpInterceptorProviders } from './_helpers/http.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent, FooterComponent } from './_shared';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { BankingDetailsComponent } from './banking-details/banking-details.component';
import { ButtonComponent } from './_shared/components/button/button.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsComponent } from './products/products.component';
import { InvestComponent } from './invest/invest.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { InvestmentComponent } from './investment/investment.component';
import { OrdersComponent } from './orders/orders.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentComponent } from './payment/payment.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { HowComponent } from './how/how.component';
import { ContactComponent } from './contact/contact.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ViewPopComponent } from './view-pop/view-pop.component';
import { InvestmentsComponent } from './investments/investments.component';
import { WithdrawalsComponent } from './withdrawals/withdrawals.component';
import { LoaderComponent } from './_shared/loader/loader.component';
import { LoadingInterceptor } from './_helpers/loading.interceptor';
import { AboutComponent } from './about/about.component';
import { SideNavComponent } from './_shared/side-nav/side-nav.component';
import { DisclaimerComponent } from './_shared/disclaimer/disclaimer.component';
import { PurchaseProtocolComponent } from './orders/purchase-protocol/purchase-protocol.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    BoardUserComponent,
    HeaderComponent,
    FooterComponent,
    ChangePasswordComponent,
    BankingDetailsComponent,
    ButtonComponent,
    DashboardComponent,
    ProductsComponent,
    InvestComponent,
    TransactionsComponent,
    WithdrawalComponent,
    InvestmentComponent,
    OrdersComponent,
    PaymentsComponent,
    PaymentComponent,
    ReferralsComponent,
    HowComponent,
    ContactComponent,
    ViewOrderComponent,
    PlaceOrderComponent,
    ViewPopComponent,
    InvestmentsComponent,
    WithdrawalsComponent,
    LoaderComponent,
    AboutComponent,
    SideNavComponent,
    DisclaimerComponent,
    PurchaseProtocolComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule
  ],
  providers: [httpInterceptorProviders, { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
