import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from '../_models';
import { OrderService, ProductService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { CustomValidators } from '../_shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseProtocolComponent } from '../orders/purchase-protocol/purchase-protocol.component';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent {
  products: Product[] = [];
  amounts: number[] = [];
  productIcons:string[] =["bi bi-house","bi bi-clipboard2-data","bi bi-briefcase","bi bi-envelope-paper-heart","bi bi-person-workspace"];
  productTheme:string[] =["secondary","primary","info","success","warning"];
  form!: FormGroup;
  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    this.productService.getProducts().subscribe((products: Product[]) => {
      this.products = products;
      this.products.forEach(p=>{p.Selected = true;});
    });

  }
  ngOnInit(): void {
    this.buildForm();
  }
  loadProducts(): void {

  }
  buildForm(): void {
    this.form = this.formBuilder.group({
      package: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
    });
  }
  placeOrder() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const model = {
      ProductId: this.package.value,
      Amount: this.amount.value
    }
    this.orderService.placeOrder(model).subscribe(success => {
      this.toastr.success('Your order was placed successfully', 'Order Request Success');
      location.reload();
    }, reject => {
      this.toastr.error(reject.error, 'Order Request Failed');
    })
  }
  updateMinimumAndMaximumInvestmentAmount(e: any): void {
    const selectedProduct = this.products.find(x => x.Id === this.package.value);
    this.loadAmountsForProduct(selectedProduct);

  }
  loadAmountsForProduct(product: Product | undefined): void {
    if (product) {
      this.getInvestmentAmounts(product);
      this.amount.clearValidators();
      this.amount.setValidators([Validators.required,CustomValidators.amountMultiple, Validators.min(product.MinimumAmount), Validators.max(product.MaximumAmount)])
      this.form.updateValueAndValidity();
      this.amount.reset();
    }
  }
  choosePackage(product: Product): void { 
    this.package.setValue(product.Id);
    this.package.updateValueAndValidity();
    this.loadAmountsForProduct(product);
   // const modal = this.modalService.open(PurchaseProtocolComponent)

  }
  toggleShow(product:Product): void{
    product.Selected = !product.Selected;
  }
  getProductTheme(product:Product|undefined): string {
    if(product?.Name=="Action Plan"){
      return ""
    }
    return "";
  }
  getInvestmentAmounts(selectedProduct: Product): number[] {

    if (selectedProduct) {
      this.amounts = [];
      for (let i = selectedProduct.MinimumAmount; i <= selectedProduct.MaximumAmount; i += selectedProduct.MinimumAmount) {
        this.amounts.push(i);
      }
    }
    return [];
  }
  get f() {
    return this.form.controls;
  }
  get package(): AbstractControl {
    return this.f['package'];
  }

  get amount(): AbstractControl {
    return this.f['amount'];
  }
  attachProofOfPayment(): void {

  }
}
