<div class="container mt-5 vh-100">
  <div class="card shadow ">
    <div class="card-body">
      <div class="card-text pb-3">
        <h3>Change Password</h3>
        <hr />
      </div>

      <form class="needs-validation" [formGroup]="form" (ngSubmit)="submit()">
        <div class="mb-3">
          <label for="currentPassword" class="form-label"
            >Current Password</label
          >
          <input
            type="password"
            class="form-control"
            id="currentPassword"
            formControlName="currentPassword"
            placeholder="current password"
            autocomplete="true"
            [ngClass]="{
              'is-valid': f['currentPassword'].valid && submitted,
              'is-invalid': f['currentPassword'].errors && submitted
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="f['currentPassword'].errors && submitted"
          >
            <div *ngIf="f['currentPassword'].errors['required']">
              Current password is required.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="newPassword" class="form-label">New Password</label>
          <input
            type="password"
            class="form-control"
            id="newPassword"
            formControlName="newPassword"
            placeholder="new password"
            autocomplete="true"
            [ngClass]="{
              'is-valid': f['newPassword'].valid && submitted,
              'is-invalid': f['newPassword'].errors && submitted
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="f['newPassword'].errors && submitted"
          >
            <div *ngIf="f['newPassword'].errors['required']">
              Password is required.
            </div>
            <div *ngIf="f['newPassword'].errors['minLength']">
              Password must be at least 6 characters long.
            </div>
            <div *ngIf="f['newPassword'].errors['maxLength']">
              Password must be at most 255 characters long.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="confirmPassword" class="form-label"
            >Confirm Password</label
          >
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            formControlName="confirmPassword"
            placeholder="confirm password"
            autocomplete="false"
            [ngClass]="{
              'is-valid': f['confirmPassword'].valid && submitted,
              'is-invalid': f['confirmPassword'].errors && submitted
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="f['confirmPassword'].errors && submitted"
          >
            <div *ngIf="f['confirmPassword'].errors['required']">
              Confirm password is required.
            </div>
            <div *ngIf="f['confirmPassword'].errors['passwordMismatch']">
              Passwords do not match.
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </div>
  </div>
</div>
