import { Component, OnInit } from '@angular/core';
import { ReferralService } from '../_services/referral.service';
import { Referral } from '../_models/referral.model';
import { Share } from '@capacitor/share';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_services';

@Component({
  selector: 'app-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent implements OnInit {
  pageSize: number = 10;
  page: number = 1;
  totalCount!: number;
  referrals: Referral[] = [];
  constructor(private referralService: ReferralService, private authService: AuthService) {


  }
  ngOnInit(): void {
    this.getReferrals(1);
  }
  async shareMyLink() {
    console.log(this.authService.getUserProfile())
    await Share.share({
      title: 'Uni Smart Swaps',
      text: 'I would like to invite you to join my team and start making money.',
      url: `${environment.appUrl}/register/${this.authService.getUserProfile().Email}`,
      dialogTitle: 'Share UniSmartSwaps',
    });
  }
  getReferrals(level: number): void {
    this.referralService.getReferrals(level).subscribe((results: Referral[]) => {

      this.referrals = results;
      this.totalCount = results.length;
    });
  }
  onLevelSelected(e: any): void {
    this.getReferrals(e.target.value);
  }
}
