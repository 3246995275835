import { Component, OnInit } from '@angular/core';
import { ApplicationStoreService, AuthService, StorageService } from 'src/app/_services';
import { GetUserSelector } from 'src/app/_store/selectors';
import { isEmpty } from "ramda"
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  title:string=environment.appName;
  user: any = {}
  constructor(private appStore: ApplicationStoreService, private storageService: StorageService, private router: Router, private authService: AuthService) { }
  ngOnInit(): void {
    setTimeout(() => {
      this.appStore.select(new GetUserSelector(this.storageService)).subscribe(results => {
        if (!isEmpty(results)) {
          this.user = results;
          this.isLoggedIn = true;
        }
        else {
          this.user = {};
          this.isLoggedIn = false;
        }
      })
    });
  }
  isLoggedOn(): boolean {
    return this.authService.isLoggedOn();
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
  goToSignup() {
    this.router.navigate(['/register']);
  }
  isAdmin(): boolean {
    return this.authService.isAdmin();
  }
  logout() {
    this.storageService.clean();
    location.reload();
  }
  GetUserFullName() {

    return this.authService.getUserProfile().Name;
  }
}
