<div class="container pt-5 vh-100">
  <div class="row">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Orders</h1>
    </div>
    <div class="row" *ngIf="this.payments.length == 0">
      <div class="card shadow rounded">
        <div class="card-body">
          <h5>No Orders Available</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6" *ngFor="let item of this.payments">
        <app-payment
          [payment]="item"
          (refreshPayments)="getPayments()"
        ></app-payment>
      </div>
    </div>
  </div>
</div>
