 <div class="card shadow rounded bg-body-tertiary">
  <div class="card-header">
    <div class="card-title">
      <h5>{{ investment.ProductName }}: {{ investment.Status }}</h5>
    </div>
  </div>
  <div class="card-body">
    <p class="row">
      <span class="col-12"
        ><ngb-progressbar
          class="mb-3"
          type="success"
          [value]="investment.Progress * 100"
          >{{ (investment.Progress * 100).toFixed(0) }}%</ngb-progressbar
        ></span
      >
    </p>
    <p class="row">
      <span class="col-6">Date Created</span>
      <span class="col-6">{{ investment.DateCreated | date }}</span>
    </p>

    <p class="row">
      <span class="col-6">Daily Returns</span>
      <span class="col-6 text-success"
        ><b>{{ investment.Amount * 0.01 * investment.DailyRate | currency : "$" }}</b></span
      >
    </p>
    <p class="row">
      <span class="col-6">Total Profit</span>
      <span class="col-6 text-success"
        ><b>{{ investment.Profit | currency : "$" }}</b></span
      >
    </p>
    <p class="row">
      <span class="col-6">Processed by Bonus</span>
      <span class="col-6 text-secondary"
        ><b>{{ investment.ReferralBonus | currency : "$" }}</b></span
      >
    </p>
    <p class="row">
      <span class="col-6">Balance</span>
      <span class="col-6 text-success"
        ><b>{{ investment.Balance | currency : "$" }}</b></span
      >
    </p>
    <p class="row" *ngIf="investment.Withdrawing">
      <span class="col-6">Withdrawing</span>
      <span class="col-6 text-success"
        ><b>{{ investment.Withdrawing | currency : "$" }}</b></span
      >
    </p>
    <div
      class=""
      *ngIf="investment.CanWithdraw"
      [hidden]="investment.Withdrawing ? true : false"
    >
      <hr />
      <div class="row">
        <div class="col">Withdraw from Balance</div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-group mb-3">
            <span class="input-group-text">$</span>
            <input
              type="text"
              [disabled]="investment.Withdrawing ? true : false"
              class="form-control"
              aria-label="Amount (to the nearest dollar)"
              [(ngModel)]="amount"
            />
            <span class="input-group-text">.00</span>
          </div>
        </div>
        <div class="col">
          <button
            class="btn btn-primary"
            [disabled]="amount <= 0 || investment.Withdrawing ? true : false"
            type="button"
            (click)="transferToWallet()"
          >
            Transfer to wallet
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
