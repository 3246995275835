<form
  [formGroup]="form"
  (ngSubmit)="attachProofOfPayment()"
  *ngIf="pendingOrder"
>
  <div class="py-3">
    <div class="position-relative m-4">
      <div
        class="progress"
        role="progressbar"
        aria-label="Progress"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
        style="height: 1px"
      >
        <div class="progress-bar" style="width: 50%"></div>
      </div>
      <button
        type="button"
        class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill"
        style="width: 2rem; height: 2rem"
      >
        1
      </button>
      <button
        type="button"
        class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-primary rounded-pill"
        style="width: 2rem; height: 2rem"
      >
        2
      </button>
      <button
        type="button"
        class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-secondary rounded-pill"
        style="width: 2rem; height: 2rem"
      >
        3
      </button>
    </div>
  </div>
  <div class="card shadow rounded bg-body-tertiary">
    <div class="card-body">
      <h4>Make Payment</h4>
      <hr />
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <p>Package</p>
          </div>
          <div class="col h4">{{ pendingOrder.ProductName }}</div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label for="amount" class="form-label">Amount</label>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <p class="h4 float-start">
                  {{ pendingOrder.Amount | currency : "$" }}
                </p>
                <button
                  class="btn btn-outline-secondary float-end"
                  (click)="copyAmount()"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-clipboard-plus"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"
                    />
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
                    />
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label for="bankName" class="form-label">Payment Method:</label>
          </div>
          <div class="col">
            <p class="h4">{{ pendingOrder.BankName }}</p>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label for="amount" class="form-label">Wallet Address:</label>
          </div>
          <div class="col">
            <div class="input-group">
              <input
                disabled
                type="text"
                class="form-control"
                value="{{ pendingOrder.BankAccountNumber }}"
                readonly
              />
              <button
                class="btn btn-secondary"
                (click)="copyAccountNumber()"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clipboard-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"
                  />
                  <path
                    d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"
                  />
                  <path
                    d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label for="amount" class="form-label">Proof of payment:</label>
          </div>
          <div class="col">
            <ng-container *ngIf="pendingOrder.ProofOfPaymentId">
              <button
                class="btn btn-primary"
                type="button"
                (click)="viewProofOfPayment()"
              >
                <i class="bi bi-eye"></i> View
              </button>
            </ng-container>
            <ng-container *ngIf="!pendingOrder.ProofOfPaymentId">
              <button
                class="btn btn-primary"
                type="button"
                (click)="uploadPOp.click()"
              >
                <i class="bi bi-upload"></i> Upload
              </button>
              <input
                hidden="true"
                type="file"
                #uploadPOp
                (change)="onFileSelected($event)"
                accept=".png,.jpeg,.jpg,.pdf"
            /></ng-container>
          </div>
        </div>
      </div>
      <div class="mb-3" *ngIf="pendingOrder.Instruction">
        <label for="amount" class="form-label">Instruction:</label>
        <p class="form-control">{{ pendingOrder.Instruction }}</p>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Transaction ID"
              aria-label="transaction ID"
              aria-describedby="button-addon2"
              required=""
              formControlName="proofOfTransaction"
            />
            <button
              class="btn btn-primary"
              type="submit"
              id="updateTransID"
              [disabled]="!pendingOrder.ProofOfPaymentId"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
