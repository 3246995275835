import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services';
import { CustomValidators } from '../_shared';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {


  form: FormGroup = new FormGroup({
    currentPassword: new FormControl(),
    newPassword: new FormControl(),
    confirmPassword: new FormControl()
  });

  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private userService: UserService
  ) { }
  ngOnInit(): void {
    this.buildForm();
  }


  buildForm(): void {
    this.form = this.formBuilder.group({
      currentPassword: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(255)]],
      newPassword: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(255)]],
      confirmPassword: ["", [CustomValidators.confirmPassdwordMatch('newPassword')]]

    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  submit(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    const model = {
      currentPassword: this.f['currentPassword'].value,
      newPassword: this.f['newPassword'].value,
      confirmPassword: this.f['confirmPassword'].value
    };

    this.userService.changePassword(model).subscribe((results) => {
      this.toaster.success('Password changed successfully', 'Update succcess');
    }, err => {
      this.toaster.error(err.error, 'Password change failed.');
    })

  }
}