<div class="container pt-5 vh-100">

  <div class="row">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2" *ngIf="!pendingOrder">Buy Protocols</h1>
      <h1 class="h2" *ngIf="pendingOrder">Payment Details</h1>
    </div>
    
  </div>
  <app-place-order *ngIf="!pendingOrder"></app-place-order>
  <app-view-order
    *ngIf="pendingOrder && !pendingOrder.ProofOfTransaction"
  ></app-view-order>

  <div class="row" *ngIf="pendingOrder && pendingOrder.ProofOfTransaction">
    <div class="col">
      <div class="py-3">
        <div class="position-relative m-4">
          <div class="progress" role="progressbar" aria-label="Progress" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style="height: 1px;">
            <div class="progress-bar" style="width: 100%"></div>
          </div>
          <button type="button" class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style="width: 2rem; height:2rem;">1</button>
          <button type="button" class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-primary rounded-pill" style="width: 2rem; height:2rem;">2</button>
          <button type="button" class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-primary rounded-pill" style="width: 2rem; height:2rem;">3</button>
        </div>
      </div>
      <div class="p-3 card shadow bg-body-tertiary">
        <h4 class="text-primary">Awaiting Confirmation</h4>
        <hr />

        <p>Your purchase is under review and this may take up to 24Hrs</p>
      </div>
    </div>
  </div>
</div>
