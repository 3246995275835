import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WithdrawalService } from '../_services/withdrawal.service';
import { WithdrawalViewModel } from '../_models/withdrawal-view-model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.css']
})
export class WithdrawalComponent {
  @Input() withdrawal!: WithdrawalViewModel;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;
  constructor(private withdrawalService: WithdrawalService, private toastr: ToastrService) {


  }

  decline(): void {
    this.loading = true;
    this.withdrawalService.declineWithdrawal({ withdrawalId: this.withdrawal.Id }).subscribe(() => {
      this.toastr.success("Processed successfully.")
      this.refresh.emit();
      setTimeout(() => {
        this.loading = false;
      });
    }, (err: any) => {
      this.toastr.error(err.error.Message ? err.error.Message : err.error)
      setTimeout(() => {
        this.loading = false;
      });
    })
    this.refresh.emit();

  }
  copyAmount(): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.withdrawal.Amount.toFixed(0));
      this.toastr.success("Copied successully")
    }
  }
  copyAccountAddress(): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.withdrawal.AccountOrWalletAddress);
      this.toastr.success("Copied successully")
    }
  }
  confirm(): void {
    this.loading = true;

    this.withdrawalService.confirmWithdrawal({ withdrawalId: this.withdrawal.Id }).subscribe(() => {
      this.toastr.success("Processed successfully.")
      this.refresh.emit();
      setTimeout(() => {
        this.loading = false;
      });
    }, (err: any) => {
      this.toastr.error(err.error.Message ? err.error.Message : err.error)
      setTimeout(() => {
        this.loading = false;
      });
    })

  }
}
