import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { all } from 'ramda';
import { BankService, UserService } from '../_services';

@Component({
  selector: 'app-banking-details',
  templateUrl: './banking-details.component.html',
  styleUrls: ['./banking-details.component.css']
})
export class BankingDetailsComponent implements OnInit {
  loading = false;
  submitted = false;
  banks: any[] = [];
  form: FormGroup = new FormGroup({
    bankId: new FormControl(),
    instructions: new FormControl(),

  });
  bankingDetails: any = {};
  constructor(
    private formBuilder: FormBuilder,
    private bankService: BankService,
    private userService: UserService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {

    Promise.all([this.userService
      .getUserBankingDetails()
      .subscribe((results) => {
        this.bankingDetails = results;
      })]).then(() => {
        this.bankService.getBanks().subscribe((results: any) => {
          this.banks = results;
          this.buildForm();
        });
      })

  }
  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    const model = {
      accountNumber: this.f['accountNumber'].value,
      bankId: this.f['bankId'].value,
      instruction: this.f['instructions'].value
    };
    this.userService.updateUserBankingDetails(model).subscribe(
      (successResponse) => {
        this.toaster.success("Wallet address updated");
        this.loading = false;
      },
      (err) => {
        this.toaster.error(err.error);
        this.loading = false;
      }
    );
  }
  buildForm() {
    this.form = this.formBuilder.group({
      accountNumber: [
        this.bankingDetails.AccountNumber || "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(128),
        ],
      ],
      bankId: [this.bankingDetails.BankId || "", [Validators.required]],

      instructions: [
        this.bankingDetails.Instruction || "",
        [Validators.maxLength(255)],
      ],
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }


}
