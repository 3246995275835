import { Component, OnInit } from '@angular/core';
import { PaymentView } from '../_models/payment-view-model';
import { PaymentService } from '../_services/payment.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  payments: PaymentView[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalRecordsCount: number = 0;

  constructor(private paymentService: PaymentService) {

  }
  ngOnInit(): void {
    this.getPayments();
  }
  getPayments(): void {
    this.paymentService.getPendingPayments().subscribe((results: any) => {
      this.payments = (results.data as PaymentView[]).filter(x => x.ProofOfTransaction);
      this.totalRecordsCount = this.payments.length;

    });
  }

}
