import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../_models';
import { ProductService, OrderService } from '../_services';
import { PaymentService } from '../_services/payment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewPopComponent } from '../view-pop/view-pop.component';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent {
  loading: boolean = false;
  buttonText: any = "Upload Proof Of Payment";
  pendingOrder!: any;
  products: Product[] = [];
  amounts: number[] = [];
  form!: FormGroup;
  transactionID!: string;
  constructor(
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
    private modalService: NgbModal
  ) {
    this.productService.getProducts().subscribe((products: Product[]) => {
      this.products = products;
    });
    this.orderService.getPendingOrder().subscribe((order: any) => {
      this.pendingOrder = order;
      this.transactionID = order.ProofOfTransaction;
      this.proofOfTransaction.setValue(order.ProofOfTransaction);
      this.proofOfTransaction.updateValueAndValidity();
    });
  }
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.form = this.formBuilder.group({
      proofOfTransaction: new FormControl(this.transactionID, [Validators.required]),
    });
  }

  copyAmount(): void {
    navigator.clipboard.writeText(this.pendingOrder.Amount).then((x) => {
      this.toastr.success('Amount copied to clipboard successfully');
    });

  }
  updateTransactionID(order: any): void {
    if (this.transactionID) {
      const model = { transactionID: this.transactionID, paymentId: order.paymentId };
      this.paymentService.updateTransactionID(model).subscribe({
        next: () => { },
        error: err => { this.toastr.error(err.error); },

      })
    }
  }
  copyAccountNumber(): void {
    navigator.clipboard.writeText(this.pendingOrder.BankAccountNumber).then((x) => {
      this.toastr.success('Account/wallet address copied successfully');
    });

  }
  get f() {
    return this.form.controls;
  }
  get proofOfTransaction(): AbstractControl {
    return this.f['proofOfTransaction'];
  }

  attachProofOfPayment(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const model = { transactionID: this.proofOfTransaction.value, paymentId: this.pendingOrder.PaymentId };

    this.paymentService.updateTransactionID(model).subscribe({
      next: () => {
        this.toastr.success('Transaction ID updated successfully');
        location.reload();
      },
      error: err => {
        this.toastr.error(err.error)
      }
    });
  }
  viewProofOfPayment(): void {
    const modalRef = this.modalService.open(ViewPopComponent)
    modalRef.componentInstance.proofOfPaymentId = this.pendingOrder.ProofOfPaymentId;
  }
  onFileSelected(e: any) {
    const pop = e.target.files[0];
    const formData = new FormData();
    formData.append("paymentId", this.pendingOrder.PaymentId);
    formData.append("proofOfPayment", pop);
    this.loading = true;
    this.paymentService.uploadPop(formData).subscribe((res: any) => {
      if (res.type > 0) {
        this.buttonText = (res.loaded * 1.0 / res.total) * 100;

      }
      if (res.ok) {
        this.loading = false;
        this.toastr.success("Uploaded pop successfully.");
        location.reload();
      }

    },
      (err: any) => {
        this.loading = false;
        this.toastr.error("Failed to upload pop");
        this.toastr.error(err.error);
      });

  }
}
