import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  baseUrl = `${environment.apiUrl}/api/referrals`;
  constructor(private http: HttpClient) { }

  getUserReferrals(page: number, pageSize: number, search: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetUserReferrals?page=${page}&pageSize=${pageSize}&search=${search}`);
  } 
  getReferrals(level: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetReferrals?level=${level}`);
  }

}
