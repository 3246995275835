import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  baseUrl = `${environment.apiUrl}/api/order`;
  constructor(private http: HttpClient) { }

  placeOrder(model: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/placeOrder`, model);
  }
  getPendingOrder(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetActiveOrder`);
  }
} 
