import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {

  baseUrl = `${environment.apiUrl}/api/withdrawal`;
  constructor(private http: HttpClient) { }

  withdraw(model: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/Withdraw`, model);
  }
  getPendingWithdrawals(): Observable<any> {
    return this.http.get(`${this.baseUrl}/GetPendingWithdrawals`);
  }
  confirmWithdrawal(model: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/confirmWithdrawal`, model);
  }
  declineWithdrawal(model: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/declineWithdrawal`, model);
  }
}
