import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from '../_shared/loader/loader.component';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private readonly modalService: NgbModal) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const modalRef: NgbModalRef = this.modalService.open(LoaderComponent, { size: "MD", backdrop: false });

    return next.handle(request).pipe(finalize(() => modalRef.close()));
  }
}
