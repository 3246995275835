<div id="app" class="">
  <ng-container *ngIf="isLogged()">
    <div class="d-flex flex-column">
      <div><app-header class="p-0"></app-header></div>
      <div class="flex-grow-1"><app-side-nav class="p-0"></app-side-nav></div>
      <div><app-footer class="p-0"></app-footer></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLogged()">
    <div class="p-5">
      <router-outlet
        class="container px-5 py-5"
        style="margin-left: auto; margin-right: auto"
      ></router-outlet></div
  ></ng-container>
</div>
