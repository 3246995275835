<div class="container pt-5 vh-100">
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2 text-primary">Protocols</h1>
   
  </div>

  <div class="row">
    <div class="col-12 col-lg-6 mb-3" *ngFor="let item of this.investments">
      <app-investment
        [investment]="item"
        (refresh)="getInvestments()"
      ></app-investment>
    </div>
  </div>
  <div class="row" *ngIf="this.investments.length == 0">
    <div class="col "  >
      <div class="card shadow rounded">
        <div class="card-body">
          <p class="card-title">
            No Investments
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
