import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
const USER_KEY = "auth-user";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  clean(): void {
    window.sessionStorage.clear();
  }
  public saveUser(user: any): void {
    const token = user.token;
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, token);
  }

  public getToken(): any {
    const token = window.sessionStorage.getItem(USER_KEY);
    if (token) {
      return token;
    }
    return "";
  }
  public getUser(): any {
    const token = window.sessionStorage.getItem(USER_KEY);

    if (token) {
      const user = jwtDecode(token);
      return user;
    }
    return {};
  }
  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }
    return false;
  }
}
