import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/_services';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {
  constructor(private modalRef: NgbActiveModal, private authService: AuthService, private router: Router) {

  }
  ngOnInit(): void {

    if (sessionStorage.getItem('disclaimer') == "I accept the disclaimer") {
      this.accept();
    }
  }
  decline(): void {
    this.authService.logout();
    this.modalRef.close();
    setTimeout(() => {

      this.router.navigateByUrl('/home').then(_=>window.location.reload());
    });

  }

  accept(): void {
    sessionStorage.setItem("disclaimer", "I accept the disclaimer");
    this.modalRef.close();
  }
}
