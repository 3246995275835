import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  baseUrl = `${environment.apiUrl}/api/payment`;
  constructor(private http: HttpClient, private storageService: StorageService) { }

  uploadPop(formData: any) {
    const headers = new HttpHeaders();
    headers.set("Authorization", `Bearer ${this.storageService.getToken()}`);
    return this.http.post<any>(
      environment.apiUrl + "/api/payment/uploadPop",
      formData,
      { headers: headers, reportProgress: true, observe: "events" }
    );
  }
  getPop(proofOfPaymentId: string) {
    return this.http.get(`${this.baseUrl}/getPop?paymentId=${proofOfPaymentId || ""}`);

  }
  getPendingPayments() {
    return this.http.get(`${this.baseUrl}/getPendingPayments`);

  }
  approvePayment(paymentId: string) {
    return this.http.post(`${this.baseUrl}/approvePayment`, { paymentId: paymentId });

  }
  declinePayment(paymentId: string) {
    return this.http.post(`${this.baseUrl}/declinePayment`, { paymentId: paymentId });

  }
  updateTransactionID(model: any) {
    return this.http.post(`${this.baseUrl}/UpdateTransactionID`, { transactionID: model.transactionID, paymentId: model.paymentId });

  }

}
