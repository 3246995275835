<div class="container pt-1 vh-100" *ngIf="dashboardSummary"> 
    <div class="row">
      <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
      >
        <h1 class="h2 text-primary">Dashboard</h1>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <div class="card shadow rounded bg-body-tertiary">
          <div class="card-body">
            <h5 class="card-title text-primary">
              <div class="d-flex flex-row">
                <div class="flex-grow-1">
                  {{ dashboardSummary.RankView.FullName }}
                </div>
                <div>
                  <b
                    ><span>Points | </span>&nbsp;<span class="text-success">{{
                      dashboardSummary.RankView.CurrentPoints
                    }}</span></b
                  >
                </div>
              </div>
            </h5>
            <hr />
            <div class="row">
              <div class="col-6">
                <p class="float-start"><b>Rank: &nbsp;</b></p>
                <p class="float-end text-warning">
                  <b>
                    {{ dashboardSummary.RankView.Rank }}
                  </b>
                </p>
              </div>
              <div class="col-6">
                <p class="float-start">
                  <b
                    >Next Rank: &nbsp;
                    {{ this.dashboardSummary.RankView.NextRank }}
                  </b>
                </p>
                <p class="float-end">
                  <b>Required Points: </b
                  ><b class="text-warning">{{
                    this.dashboardSummary.RankView.NextRankPoints
                  }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ngb-progressbar
                  class="mb-3"
                  type="success"
                  textType="white"
                  [value]="dashboardSummary.RankView.PercentageToNextRank"
                  [showValue]="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <div class="card shadow rounded bg-body-tertiary">
          <div class="card-body">
            <h5 class="card-title text-primary">Dashboard Summary</h5>
            <hr />
            <div class="row">
              <div class="col-6">
                <p class="float-start"><b>Lending Amount</b></p>
                <p class="float-end text-warning">
                  <b>{{
                    this.dashboardSummary.TotalDeposited | currency : "$ "
                  }}</b>
                </p>
              </div>
              <div class="col-6">
                <p class="float-start"><b>Returns</b></p>
                <p class="float-end text-success">
                  <b>{{
                    this.dashboardSummary.TotalProfit | currency : "$ "
                  }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p>
                  <span class="float-start"><b>Protocols Balance</b></span
                  ><span class="float-end text-success"
                    ><b>{{
                      this.dashboardSummary.TotalPackageBalance
                        | currency : "$ "
                    }}</b>
                  </span>
                </p>
              </div>
              <div class="col-6">
                <p>
                  <span class="float-start"><b>Protocols</b></span
                  ><span class="float-end text-success">
                    <a
                      class="btn btn-sm btn-outline-secondary"
                      [routerLink]="['/packages']"
                      routerLinkActive="router-link-active"
                      ><b>{{
                        this.dashboardSummary.TotalActivePackages +
                          this.dashboardSummary.TotalPaidPackages
                      }}</b></a
                    >
                  </span>
                </p>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-6">
                <p>
                  <span class="float-start"><b>Referrals</b></span
                  ><span class="float-end text-success"
                    ><b>
                      <a
                        class="btn btn-sm btn-outline-secondary"
                        [routerLink]="['/referrals']"
                        routerLinkActive="router-link-active"
                      >
                        {{ this.dashboardSummary.TotalReferrals }}</a
                      ></b
                    >
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <div class="card shadow rounded bg-body-tertiary">
          <div class="card-body">
            <h5 class="card-title text-primary">Wallet</h5>
            <hr />
            <div class="row">
              <div class="col-6">
                <p class="float-start"><b>Total Balance</b></p>
                <p class="float-end text-warning">
                  <b>{{
                    this.dashboardSummary.TotalBalance | currency : "$ "
                  }}</b>
                </p>
              </div>
              <div class="col-6">
                <p class="float-start"><b>Bonus</b></p>
                <p class="float-end text-success">
                  <b>{{
                    this.dashboardSummary.ReferralBonus | currency : "$ "
                  }}</b>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <div class="input-group mb-3">
                      <span class="input-group-text">$</span>
                      <input
                        type="text"
                        [disabled]="dashboardSummary.TotalBalance <= 0"
                        class="form-control"
                        aria-label="Amount (to the nearest dollar)"
                        [(ngModel)]="withdrawalAmount"
                      />
                      <span class="input-group-text">.00</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary"
                      [disabled]="withdrawalAmount <= 0"
                      type="button"
                      (click)="withdrawFromBalance()"
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-12">
                    <p>
                      <span class="float-start"><b>Withdrawing</b></span
                      ><span class="float-end text-success"
                        ><b>{{
                          this.dashboardSummary.CurrentWithdrawal
                            | currency : "$ "
                        }}</b>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col">
        <div class="card shadow rounded bg-body-tertiary">
          <div class="card-body">
            <h5 class="card-title text-primary">Protocols</h5>
            <hr />
            <div class="card-text">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table table-striped table-hover">
                      <thead>
                        <th>#</th>
                        <th>Package</th>
                        <th>Lending Amount</th>
                        <th>Daily Return</th>
                        <th>Balance</th>
                        <th>Returns</th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of investmentList; let i = index">
                          <td>{{ i + 1 }}</td>
                          <td>{{ item.ProductName }}</td>
                          <td class="text-warning">
                            {{ item.Amount | currency : "$" }}
                          </td>
                          <td class="text-primary">
                            {{
                              item.Amount * 0.01 * item.DailyRate
                                | currency : "$"
                            }}
                          </td>
                          <td class="text-success">
                            {{ item.Balance | currency : "$" }}
                          </td>
                          <td class="text-success">
                            {{ item.Profit | currency : "$" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
</div>
