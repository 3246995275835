import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.css"]
})
export class ButtonComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() btnText: string = '';
  @Output() onClick = new EventEmitter<any>();
  @Input() btnClass!: string;
  @Input() btnType!: string;
  @Input() disabled: boolean = false;
  @Input() icon!: string;
  constructor() { }

  ngOnInit() { }
  click(e: any) {
    this.onClick.emit(e);
  }
}
