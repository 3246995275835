<div class="container mt-5 vh-100">
  <div class="card shadow rounded  bg-body-tertiary">
    <div class="card-body">
      <div class="card-text pb-3">
        <h3>Update Personal Details</h3>
        <hr />
      </div>

      <form class="needs-validation" [formGroup]="form" (ngSubmit)="submit()">
        <div class="mb-3">
          <label for="firstName" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            id="firstName"
            formControlName="name"
            [ngClass]="{
              'is-valid': f['name'].valid && submitted,
              'is-invalid': f['name'].errors && submitted
            }"
          />
        </div>
        <div class="mb-3">
          <label for="lastName" class="form-label">Surname</label>
          <input
            type="text"
            class="form-control"
            id="lastName"
            formControlName="surname"
            [ngClass]="{
              'is-valid': f['surname'].valid && submitted,
              'is-invalid': f['surname'].errors && submitted
            }"
          />
        </div>
        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Phone number</label>
          <input
            type="tel"
            class="form-control"
            id="phoneNumber"
            formControlName="phoneNumber"
            [ngClass]="{
              'is-valid': f['phoneNumber'].valid && submitted,
              'is-invalid': f['phoneNumber'].errors && submitted
            }"
          />
          <div
            class="invalid-feedback"
            *ngIf="f['phoneNumber'].errors && submitted"
          >
            <div *ngIf="f['phoneNumber'].errors['required']">
              Phone number is required.
            </div>
            <div *ngIf="f['phoneNumber'].errors['invalidPhone']">
              Invalid phone number.
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            [disabled]="true"
            formControlName="email"
            [ngClass]="{
              'is-valid': f['email'].valid && submitted,
              'is-invalid': f['email'].errors && submitted
            }"
          />
        </div>
        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </div>
  </div>
</div>
