<div class="container py-3" *ngIf="false">
    <header>
 
  
      <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h1 class="display-4 fw-normal text-body-emphasis">Our Packages</h1>
        <p class="fs-5 text-body-secondary">The investment plans offered are summarized below:</p>
      </div>
    </header>
  
    <main>
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Lite</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title"> $100<small class="text-body-secondary fw-light"></small></h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Duration 2 weeks</li>
                <li>Return of Investment 20%</li>
                <li>Add more description</li> 
                <li>Add more description</li> 
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-outline-primary">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm">
            <div class="card-header py-3">
              <h4 class="my-0 fw-normal">Core</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">$500<small class="text-body-secondary fw-light"></small></h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>Duration 1 Month</li>
                <li>ROI 50%</li>
                <li>Add more description</li> 
                <li>Add more description</li> 
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-outline-primary">Purchase</button>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4 rounded-3 shadow-sm border-primary">
            <div class="card-header py-3 text-bg-primary border-primary">
              <h4 class="my-0 fw-normal">Premium</h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">$1500<small class="text-body-secondary fw-light"></small></h1>
              <ul class="list-unstyled mt-3 mb-4">
                <li>1 Month</li>
                <li>ROI 80%</li>
                <li>Add more description</li> 
                <li>Add more description</li> 
              </ul>
              <button type="button" class="w-100 btn btn-lg btn-outline-primary">Purchase</button>
            </div>
          </div>
        </div>
      </div>
  
      <h2 class="display-6 text-center mb-4">Compare plans</h2>
  
      <div class="table-responsive">
        <table class="table text-center">
          <thead>
            <tr>
              <th style="width: 34%;"></th>
              <th style="width: 22%;">Free</th>
              <th style="width: 22%;">Pro</th>
              <th style="width: 22%;">Enterprise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="text-start">Public</th>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Private</th>
              <td></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            </tr>
          </tbody>
  
          <tbody>
            <tr>
              <th scope="row" class="text-start">Permissions</th>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Sharing</th>
              <td></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Unlimited members</th>
              <td></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            </tr>
            <tr>
              <th scope="row" class="text-start">Extra security</th>
              <td></td>
              <td></td>
              <td><svg class="bi" width="24" height="24"><use xlink:href="#check"></use></svg></td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
   
  </div>