import { AbstractControl, ValidatorFn, FormGroup } from "@angular/forms";

export class CustomValidators {
    static phoneNumberValidator(
        control: AbstractControl
    ): { [key: string]: boolean } | null {
        if (control.pristine) {
            return null;
        }
        const phoneRegex: RegExp = /^((\+|00)?[0-9]{2}|0)[1-9]([0-9]){8,15}$/;
        const phone = control.value as string;
        return phone.match(phoneRegex) ? null : { invalidPhone: true };
    }

    static emailValidator(
        control: AbstractControl
    ): { [key: string]: boolean } | null {
        if (control.pristine) {
            return null;
        }
        const emailValidationRegex: RegExp = /^[a-z0-9_]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$/gi;
        const email = control.value;
        return email.match(emailValidationRegex) ? null : { invalidEmail: true };
    }

    static amountMultiple(control: AbstractControl): { [key: string]: boolean } | null {

        if (control.pristine) {
            return null;
        }
        const amount = Number(control.value);
        if (!amount || amount % 50 != 0) return { invalidAmount: true };

        return null
    }

    static nameValidator(reg: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.pristine) {
                return null;
            }
            const name = control.value as string;
            return name.match(reg) ? null : { invalidName: true };
        };
    }


    static confirmPassdwordMatch(password: string): any {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const currentPassword = control.parent?.value[password];
            if (currentPassword !== control?.value) {
                control?.setErrors({ passwordMismatch: true });
                return { passwordMismatch: true };
            }
            control?.setErrors(null);

            return null;
        };
    }
}
