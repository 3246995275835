import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, map, Observable } from "rxjs";
import { Action } from "../_store/actions/action";
import { IApplicationState } from "../_store/application-state";
import { Selector } from "../_store/selectors/selector";

@Injectable({
    providedIn: 'root'
})

export class ApplicationStoreService {

    private readonly INITIAL_STATE: IApplicationState = {};
    private state: BehaviorSubject<IApplicationState> = new BehaviorSubject<IApplicationState>(this.INITIAL_STATE)
    private state$: Observable<IApplicationState> = this.state.asObservable();

    private action: BehaviorSubject<Action | null> = new BehaviorSubject<Action | null>(null);
    public action$: Observable<Action | null> = this.action.asObservable().pipe(filter(action => !! action));


    public select<T>(selector: Selector<T>): Observable<T> {
        return this.state$.pipe(map(state=>selector.select(state)))
    }

    public dispatch(action: Action): void {
        const currentState:  IApplicationState = this.state.value;
        const newState: IApplicationState = action.reduce(currentState);

        if(currentState !== newState){
            this.state.next(newState);
        }
        this.action.next(action);
    }
}