import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BankingDetailsComponent } from './banking-details/banking-details.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { ProductsComponent } from './products/products.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvestComponent } from './invest/invest.component';
import { PaymentsComponent } from './payments/payments.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { HowComponent } from './how/how.component';
import { ContactComponent } from './contact/contact.component';
import { LoggedOnGuard } from './_guard/logged-on.guard';
import { AdminGuard } from './_guard/admin.guard';
import { InvestmentsComponent } from './investments/investments.component';
import { WithdrawalsComponent } from './withdrawals/withdrawals.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register/:sponsor', component: RegisterComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [LoggedOnGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [LoggedOnGuard] },
  { path: 'wallet-address', component: BankingDetailsComponent, canActivate: [LoggedOnGuard] },
  { path: 'mod', component: BoardModeratorComponent, canActivate: [LoggedOnGuard] },
  { path: 'admin', component: BoardAdminComponent, canActivate: [LoggedOnGuard] },
  { path: 'products', component: ProductsComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [LoggedOnGuard] },
  { path: 'buy', component: InvestComponent, canActivate: [LoggedOnGuard] },
  { path: 'payments', component: PaymentsComponent, canActivate: [LoggedOnGuard, AdminGuard] },
  { path: 'orders', component: PaymentsComponent, canActivate: [LoggedOnGuard, AdminGuard] },
  { path: 'withdrawals', component: WithdrawalsComponent, canActivate: [LoggedOnGuard, AdminGuard] },
  { path: 'packages', component: InvestmentsComponent, canActivate: [LoggedOnGuard] },
  { path: 'referrals', component: ReferralsComponent, canActivate: [LoggedOnGuard] },
  { path: 'how', component: HowComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
